body {
    padding: 0;
    font-family: 'Open Sans', Sans-serif !important;
}

.container-circular{
    display: flex;
    width: 420px;
    padding: 50px 0;
    border-radius: 8px;
    background: #fff;
    row-gap: 30px;
    flex-direction: column;
    align-items: center;
}
.circular-progress{
    position: relative;
    height: 250px;
    width: 250px;
    border-radius: 50%;
    background: conic-gradient(#7d2ae8 3.6deg, #ededed 0deg);
    display: flex;
    align-items: center;
    justify-content: center;
}
.circular-progress::before{
    content: "";
    position: absolute;
    height: 210px;
    width: 210px;
    border-radius: 50%;
    background-color: #fff;
}
.progress-value{
    position: relative;
    font-size: 40px;
    font-weight: 600;
    color: #7d2ae8;
}


.wordingError {
    color: red;
    font-size: 15px;
}

.fullwidth {
    width: 100% !important;
}

.navbar {
    padding: 20px !important;
}

.nav-item:hover {
    color: #F1A953;
    transition: 0.5s;
    border-radius: 5px;
}


.nav-item {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 28px;
    letter-spacing: 0px;
}

.navbarItem {
    color: #52504E !important;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;
    line-height: 28px;
    letter-spacing: 0px;
}

.navbarItem:hover {
    color: #F1A953 !important;
    cursor: pointer;
}

.navbarItem.active {
    color: #F1A953 !important;
}

.navbar-collapse {
    display: flex;
    justify-content: flex-end;
}

.navbar-brand {
    position: relative;
    background: url(../public/assets/img/westgate-surgical-logo-2x.png);
    height: 85px;
    width: 150px;
    left: 15px;
    background-size: contain;
}

.nav-brand {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-style: normal;
    font-size: 18px;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding: 10px;
    color: #52504E !important;
}

.dropdown-item.active {
    background-color: white !important;
    font-weight: bold;
}

.dropdown-item:active {
    background-color: white !important;
    color: #F1A953 !important;
}

.homepage-banner {
    min-height: 447px;
    height: calc(100vh - 200px) !important;
    background: url(../public/assets/img/homepage-bg-03.jpeg);
    /* background: url(../public/assets/img/homepage-bg-02.jpg); */
    background-size: cover;
}

.homepage-banner-mobile {
    min-height: 447px;
    /* height: calc(100vh - 200px) !important; */
    background: url(../public/assets/img/homepage-bg-02-mobile.jpg);
    background-size: cover;
}

.page-banner {
    min-height: 300px;
    background: url(../public/assets/img/page-banner.JPG);
    background-size: cover;
    background-position: 50% 10%;
    width: calc(100vh);
    height: 300px;
}

/* .homepage-banner {
    display: block;
    max-width: 100%;
    max-height: 100%;
} */


.heading-main-box {
    top: 100px !important;
}

.heading-main-text-box {
    background-color: black;
    opacity: 0.2;
    padding: 2px;
}

.heading-main {
    font-size: 28px;
    font-weight: 600;
    line-height: 1.3em;
    letter-spacing: 1.2px;
    padding-bottom: 5px;
    color: white;
    text-shadow: 0px 0px 5px black;
}

.sub-headline {
    /* text-align: justify; */
    /* font-weight: 600; */
    font-size: 18px;
    text-align: center ;
}

.sub-headline-alt {
    text-align: left;
    /* font-weight: 600; */
    font-size: 18px;
}

.subheading-main {
    font-size: 20px !important;
    font-weight: 400;
    font-family: 'Open sans', sans-serif !important;
    color: gainsboro;
    text-shadow: 0px 0px 5px black;
}


.contentbox-headline {    
    font-weight: 500;
    font-size: 18px;
}

.wording-default,
.contentBoxText {
    font-size: 20px !important;
    font-family: "Open Sans";
    color: #52504E;
    text-align: left;
}


.wording-padding {
    padding-left: 45px !important;
}

.map-padding {
    padding: 0 100px 100px 100px;
}

.contentBox {
    padding-top: 100px;
    padding-bottom: 100px;
}

.contentBoxLow {
    padding-top: 80px;

}

.contentBoxFixed {
    padding-top: 150px;
}

.contentBoxTitle {
    font-size: 20px;
    text-transform: uppercase;
    font-family: 'Open sans', sans-serif;
    font-weight: 600;
}

.contentBoxTitleDetailServices {
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 600;
}

/* .contentBoxDetail {
    font-size: 16px;
} */


.contentBoxDetail {
    /* font-family: 'Open sans'; */
    font-style: normal;
    font-weight: 550;
    font-size: 15px;
    text-align: left;
    color: #454544;
    margin: 20px 0;
    line-height: 30px;
}

.underlineTitle {
    width: 100px;
    height: 2px;
    background-color: #F1A953;
    margin: 15px 0 15PX 0;
}

.underlineTitleShort {
    width: 60px;
    height: 2px;
    background-color: #F1A953;
    margin: 15px 0 15PX 0;
}

.underlineTitleCentre {
    width: 100px;
    height: 2px;
    background-color: #F1A953;
    margin: 15px auto 15px auto;
}


.underlineTitleForm {
    width: 100px;
    height: 2px;
    background-color: #F1A953;
    margin: 25px 0 25PX 0;
}

.containerImage {
    border: 2px solid #333333;
    width: 60%;
    height: 20%;
}

.paragraphContent {
    font-family: 'Open sans';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    text-align: left;
    color: #454544;
    margin: 10px 0;
    line-height: 32px;
    padding-bottom: 15px;
}

.underlineTitleFooter {
    width: 100px;
    height: 2px;
    background-color: #F1A953;
    margin: 25px 0 25PX 0;
}

.servicesIconCircle {
    border: 3px solid #F1A953;
    padding: 20px;
    background-color: #FFFFFF;
}

.servicesIcon {
    display: block;
    width: 64px;
    height: 64px;
    margin-left: auto;
    margin-right: auto;
}

.servicesTitle {
    padding-top: 25px;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    display: block;
    text-transform: capitalize;
    font-family: 'open sans', sans-serif;
    text-align: center;
}

.form-title {
    padding-top: 25px;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    display: block;
    text-transform: capitalize;
    font-family: 'open sans', sans-serif;    
}

.servicesLink .linkButtonText {
    font-size: 18px;
    font-weight: 600;
    color: #333333;
    display: none;
    text-transform: capitalize;
}

.servicesIconSet {
    padding: 50px;
}

.servicesIcons {
    padding: 50px;
}
.react-datepicker-wrapper{
    width: 48% !important;
}

.react-date-picker__wrapper{
    border: none !important;
}

.servicesIconSet:hover {
    background-color: #f6f5f4;
    /* cursor: pointer; */
}

.servicesIconSet:hover .linkButtonText {
    background-color: #f6f5f4;
    /* cursor: pointer; */
    color: #333333 !important;
    font-weight: 600 !important;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px !important;
    letter-spacing: 1.2px;

    border: 1px solid;
    display: flex;
    justify-content: center;
    width: 150px;
    height: 50px;
    align-items: center;
    margin: 25px auto 0;
}

.servicesIconRow {
    padding-top: 50x;
    padding-bottom: 50px;
}

.linkButtonText {
    color: #333333 !important;
    font-weight: 800 !important;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px !important;
    letter-spacing: 1.2px;
    font-family: 'Open sans', sans-serif;
    border: 1px solid;
    display: flex;
    justify-content: center;
    width: 200px;
    height: 50px;
    align-items: center;
}

.linkButtonText:hover {
    color: white !important;
    background-color: #F1A953 !important;
    transition: 0.5s;
}

.linkSubmitText {
    background-color: #E99323 !important;
    color: #FFFFFF !important;
    font-weight: 600 !important;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px !important;
    letter-spacing: 1.2px;
    font-family: 'Open sans', sans-serif !important;
    border: 1px solid;
    display: flex;
    justify-content: center;
    width: 200px;
    height: 50px;
    align-items: center;
}

.linkSubmitText:hover {
    color: white !important;
    background-color: #F1A953 !important;
    transition: 0.5s;
}

.contentBoxMainImage {
    color: #F1A953;
    box-shadow: 10px 10px;
    height: 500px !important;
    width: 480px !important;
    margin: -50px 0 100px 0;
}

.contentBoxAboutImage {
    height: 237px !important;
    width: 228px !important;
    color: #F1A953;
    box-shadow: 10px 10px;
}

section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 400px;
    padding-top: 100px;
    background: #3c31dd;
}

img.ebookImage {
    width: 35%;
    padding: 10px;
    /* height: 100%; */
    /* border: 1.5px solid #5d5d5d; */
    background-color: white;
    box-shadow: 4px 4px gainsboro;
}

.curved-left {
    background-color: white;
    border-bottom-left-radius: 50% 100%;
    height: 50px;
    width: 100%;
}

.curved-right {
    background-color: #52504E;
    border-top-right-radius: 60% 200%;
    height: 50px;
    width: 100%;
}

.curved-left-paragraph {
    background-color: #f5f4f3;
    border-bottom-left-radius: 50% 100%;
    height: 50px;
    width: 100%;
}

.curved-right-paragraph {
    background-color: white;
    border-top-right-radius: 60% 200%;
    height: 50px;
    width: 100%;
}

.curved-left-paragraph-ods {
    background-color: white;
    border-bottom-left-radius: 50% 100%;
    height: 50px;
    width: 100%;
}

.curved-right-paragraph-ods {
    background-color: #f5f4f3;
    border-top-right-radius: 60% 200%;
    height: 50px;
    width: 100%;
}

.curved-right-shadow {
    color: #F1A953;
    box-shadow: 10px -5px 2px;
}

.paragraph-bg {
    background-color: #ffff;
    color: black;
}

.paragraph-ods-bg {
    background-color: #f5f4f3;
    color: black;
}

.footer-bg {
    background-color: #52504E;
}

.footer-text-color {
    color: white !important;
}

.paragraph-text-color {
    color: #52504E !important;
}

.curved-bg2 {
    background-color: white;

}

.linkFooter {
    font-size: 14px;
    text-decoration: none;
    color: gainsboro;
    margin-bottom: 10px;
    display: block !important;
}

.linkText {
    margin-left: 5px;
    margin-right: 5px;
    text-decoration: none;
    color: #F1A953 !important;
}

.linkTitleContent {
    font-family: 'Open sans', sans-serif !important;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    color: #1F2B6C !important;
    font-style: normal;
    /*  */
    text-decoration: none;
}

.linkText:hover {
    cursor: pointer;
}

.containerPage {
    display: flex;
    justify-content: center;
    padding: 25px;
}

.boxPage {
    width: 758px;
}

.boxPageTitle {
    width: 758px;
}

.containerTitlePage {
    display: flex;
    justify-content: flex-start;
}

.boxTitle {
    padding: 0;
}

.paragraph-even {
    background-color: #f5f4f3 !important;
}




.accordion-button:not(.collapsed) {
    background-color: #F1A953 !important;
    color: #52504E !important;
    font-weight: bold !important;
}

.imageContainer {
    display: flex;
    padding: 15px;
    justify-content: center;
}

.imageCard {
    background-color: #F5F5F5;
    border-radius: 8px;
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tickBox {
    padding: 0 10px;
    font-size: 16px;
}

.tickBoxTop {
    padding: 0 10px;
    font-size: 16px;
}

.containerRight {
    font-size: 1.5em;
    display: flex;
    flex-wrap: wrap;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    flex-direction: row-reverse;
    width: 50% !important;
}

.containerLeft {
    font-size: 1.5em;
    display: flex;
    flex-wrap: wrap;
    /* border-right:2px solid black; */
    border-bottom: 2px solid black;
    width: 50% !important;
}


.titleContent {
    font-family: 'Open sans';
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    color: #1F2B6C !important;
    font-style: normal;
}


.paragraph-title {
    font-family: 'Open sans';
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    color: #1F2B6C !important;
    font-style: normal;
}

.titleIconDetail {
    margin-top: -80px !important;
}

.titleList {
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    font-family: 'Open sans', sans-serif;
    text-align: left;
    margin-right: 15px;
}

.titleDescription {
    font-size: 13px;
    font-style: italic;
    padding: 0 5px;
}

.subtitleList {
    font-weight: 400;
}

.contentCenter {
    text-align: center;
    margin: 5px auto !important;
}

.uploadRadioFile {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid
}

#upload-photo {
    opacity: 0;
    position: absolute;
    z-index: -1;
}

.inputFileLabel:hover {
    cursor: pointer;
}

.formContact {
    background-color: #FFFFFF;
}

.paddingTitle {
    padding: 25px 0 0 0 !important;
}

.fontSizeSubtitle {
    font-size: 15px !important;
    font-weight: 400 !important;
}

.fontSizeTitle {
    font-size: 20px;
}

.uploadFile {
    border: 1px dashed #C9C9C9;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 104px;
    background-color: #ffffff
}

.uploadFileSuccess {
    color: white
}

.uploadFileButton {
    color: #C9C9C9;
    font-family: 'Open sans', sans-serif !important;
    font-style: normal;
    font-weight: 400;
}

.map-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.map-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

.FaqList {
    margin: 0 25px;
}

@media only screen and (max-width: 600px) {
    .navbar-collapse {
        display: flex;
        justify-content: flex-start;
    }

    /* .banner{
          border-end-start-radius: 10px 0px !important;
            border-end-end-radius: 100px  !important;
         } */
    .titleBanner {
        display: flex;
        justify-content: center;
    }

    .underlineTitleCenter {
        width: 100px;
        height: 2px;
        background-color: #F1A953;
        margin: 25px auto 25px auto;
    }

    .underlineTitleLeft {
        width: 100px;
        height: 2px;
        background-color: #F1A953;
    }

    .contentBoxAboutImage {
        margin: 0 0 10px 0;
        width: 340px !important;
        height: 350px !important;
    }

    .subtitleBanner {
        width: 80% !important;
        padding: 0 0 45px 25px;
        margin-right: auto;
        margin-left: auto;
        text-align: left !important;
        font-size: 18px;
    }

    .boxPage {
        width: 100% !important;
        padding: 0;
    }

    .containerPage {
        margin: 0;
    }

    .boxParagraphBanner {
        width: 100% !important;
    }

    .tickBoxTop {
        padding: 0 3px;
    }

    .boxPageTitle {
        width: 80% !important;
    }
}


.form-label {
    line-height: "21px";
}

.form-select {
    font-family: 'Open sans', sans-serif;
}

.form-control {
    background-color: #f5f4f3 !important;
    font-family: 'Open sans', sans-serif;
}

.form-check-label {
    font-family: 'Open sans';
    font-style: "normal";
    font-size: "16px";
    line-height: "21px";
}

.pb-6 {
    padding-bottom: 5rem !important;
}

.bankAccText {
    font-weight: 600
}

.cardMap {
    box-shadow: 8px 8px whitesmoke;
}

@media only screen and (max-width:400px) {
    .homepage-banner {
        min-height: auto;
        width: 100% !important;
        height: calc(53vh - 200px) !important;
        /* background: url(http://localhost:3000/static/media/homepage-bg-02.7a29aee….jpg);
        background-size: cover;
        width: 100% !important; */
        /* height: calc(72vh - 200px) !important; */
    }

    .navbar-brand {
        position: relative;
        /* background: url(../public/assets/img/kai-logo-mobile.png); */
        /* height: 85px;
        width: 150px;
        left: 15px;
        background-size: contain; */
    }
}